import React from "react"
import Layout from "../../../components/Layout/Layout"
import HomeLayout from "../../../components/HomeLayout/HomeLayout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const Timings = () => {
  const data = useStaticQuery(graphql`
    query {
      Timings: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Timings" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <HomeLayout>
        {data.Timings.nodes.map(img => (
          <Img
            fluid={img.childImageSharp.fluid}
            alt="Timmings images"
            key={img.id}
          />
        ))}
      </HomeLayout>
    </Layout>
  )
}

export default Timings
